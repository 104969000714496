/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/esm/Col'
// import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Spinner from 'react-bootstrap/Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCirclePlus,
  faCopy,
  faEdit,
  faLink,
  faMagnifyingGlass,
  faRotateRight,
  faArrowDownWideShort,
} from '@fortawesome/free-solid-svg-icons'
import { Dropdown } from 'react-bootstrap'
import { apiAuthInstance } from '../../apis/backend'
// import LoadingButton from '../../components/shared/LoadingButton'
import ModalSubmitWarning from '../../components/modal/ModalSubmitWarning'
import TableList from '../../components/shared/TableList'
import Navbar from '../../components/shared/Navbar'
import Footer from '../../components/shared/Footer'
import Toolbar from '../../components/shared/Toolbar'
import ModalApiStatus from '../../components/modal/ModalApiStatus'
import ModalApplicationDetail from '../../components/modal/ModalApplicationDetail'

export default function AdminManagement({ settings }) {
  const navigate = useNavigate()

  const { auth_rank, email } = settings.state.authData
  const isOrcaAdmin = auth_rank === 'orca_admin'
  const isBmAdmin = auth_rank === 'bm_admin'
  const isBmUser = auth_rank === 'bm_user'

  const initSpecificApplicationData = {
    application: {
      status: '',
      remark: '',
      is_authorized: false,
    },
    applicant_data: {
      user: '',
      company_name: '',
      oca_name: '',
      contact_number: '',

      mailchimp_addr_address: '',
      mailchimp_addr_city: '',
      mailchimp_addr_state: '',
      mailchimp_addr_zip_code: '',
      mailchimp_addr_country: '',

      mail_topic: '【ORCA BM 系統通知】ORCA 開通完成',
      mail_content: [
        {
          field: '',
          type: 'fixed',
          value: '您已成功開通 ORCA! 請用以下帳號密碼登入。',
        },
        { field: 'account', type: 'text', value: `帳號：` },
        { field: 'password', type: 'text', value: `密碼：` },
      ],
      account: '',
      password: '',
    },

    ma_data: {
      mailchimp_reply_to: '',
      mailchimp_from_name: '',

      line_id: '',
      line_name: '',
      line_channel_access_token: '',
      line_channel_secret: '',

      facebook_app_id: '',
      facebook_app_secret: '',
    },

    sample_data_csv: {
      crm_profile_csv_url: '',
      crm_profile_csv_name: '',

      items_csv_url: '',
      items_csv_name: '',

      orders_csv_url: '',
      orders_csv_name: '',
    },

    sample_data_mapping: {
      crm_profile_crm_id: '',
      crm_profile_email: '',
      crm_profile_phone: '',

      items_item_id: '',
      items_profit_center: '',
      items_type: '',
      items_segment_1: '',
      items_segment_2: '',
      items_segment_3: '',

      orders_crm_id: '',
      orders_order_date: '',
      orders_order_number: '',
      orders_order_amount: '',
      orders_item_id: '',
      orders_quantity: '',
      orders_unit_selling_price: '',
      orders_actual_selling_price: '',
    },

    formal_data_csv: {
      crm_profile_csv_url: '',
      crm_profile_csv_name: '',

      items_csv_url: '',
      items_csv_name: '',

      orders_csv_url: '',
      orders_csv_name: '',
    },
  }
  const initModalAction = {
    type: '',
    targetId: undefined,
  }
  const initModal = {
    action: initModalAction,
    loaded: true,
    show: false,
    warningModalShow: false,
    linksModalShow: false,
    membersModalShow: false,
    projectModalShow: false,
    projectUpdateRecordModalShow: false,
    userDataModalShow: false,
  }
  const initApiStatus = {
    status: '',
    message: '',
    apiStatusModalShow: false,
  }
  const [modal, setModal] = useState(initModal)
  const [specificApplication, setSpecificApplication] = useState(
    initSpecificApplicationData
  )
  const [apiStatus, setApiStatus] = useState(initApiStatus)
  const [applications, setApplications] = useState(false)

  const [isFormalData, setIsFormalData] = useState(false)
  const [applicationId, setApplicationId] = useState('')
  const [projectUpdateRecords, setProjectUpdateRecords] = useState([])

  // OCA & OCM 站點
  const initLinks = {
    oca_links: 'https://oca-name.orca.punwave.com/',
    ocm_links: 'https://orcama-plus.punwave.com',
  }
  const [links, setLinks] = useState(initLinks)

  const [maCompId, setMaCompId] = useState('')
  const [sibKey, setSibKey] = useState('')

  // ApplicationDetail
  const initApplicationDetail = {
    applicationDetailModalShow: false,
    data: {
      oca_name: '',
      create_time: '',
      expired_time: '',
      applicant: '',
      version: '',
      ma_comp_id: '',
      sib_key: '',
      orca_analytics_url: '',
      orca_ma_url: '',
      data_src: '',
      application_src: '',
      company_name: '',
      tax_id: '',
      contact_number: '',
      comp_default_language: '',
      app_remark: '',
    },
  }
  const [applicationDetail, setApplicationDetail] = useState(
    initApplicationDetail
  )
  const initUserData = {
    acc: '',
    pwd: '',
  }
  const [userData, setUserData] = useState(initUserData)
  const initConfirmationSignal = false
  const [confirmationSignal, setConfirmationSignal] = useState(
    initConfirmationSignal
  )

  async function putPendingApplicationStatus(id) {
    await apiAuthInstance({
      url: 'applications/client-application/',
      method: 'put',
      data: {
        confirmation: {
          status: specificApplication.application.status,
        },
      },
      params: {
        application_id: id,
      },
    })
      .then(() => {})
      .catch(() => {})
  }
  async function getApplications() {
    await apiAuthInstance({
      url: 'applications/client-application/',
      method: 'get',
      params: {
        user_permission: 'orca_admin',
        user_email: email,
      },
    })
      .then((response) => {
        const statusMap = {
          requested: '收到orca app開站申請',
          key_filled: '填寫 sib_key, comp_id 完成',
          url_filled: '填寫開站 URL 完成',
          login_checked: '檢查登入成功',
          notification_sent: '開站完成並寄送通知信',
          error_key_filling: '填寫 sib_key, comp_id 出錯',
          error_url_filling: '填寫開站 URL 出錯',
          error_login_check: '檢查登入出錯',
          error_notification: '寄送通知信出錯',
        }
        const processedData = response.data.data.map((app) => ({
          ...app,
          app_status: app.app_status ? statusMap[app.app_status] : '-',
          // oca_name: app.oca_name ?? '-',
        }))

        setApplications(processedData)
      })
      .catch((error) => {
        switch (error.response.status) {
          case 401:
            navigate('/home')
            break
          default:
            break
        }
      })
  }
  async function getProjectUpdateRecords() {
    await apiAuthInstance({
      url: `project/update-record/${applicationId}/`,
      method: 'get',
    })
      .then((response) => {
        setProjectUpdateRecords(response.data.data)
      })
      .catch(() => {})
  }
  async function putProject() {
    await apiAuthInstance({
      url: `project/project/${applicationId}/`,
      method: 'put',
      data: {
        ma_comp_id: maCompId,
        sib_key: sibKey,
      },
    })
      .then((response) => {
        setModal((prevState) => ({
          ...prevState,
          loaded: true,
          show: false,
          warningModalShow: false,
        }))
        setApiStatus({
          status: response.data.status,
          message: response.data.message,
          apiStatusModalShow: true,
        })
        getApplications()
      })
      .catch((error) => {
        setApiStatus({
          status: error.response.data.status,
          message: error.response.data.message,
          apiStatusModalShow: true,
        })
      })
  }
  async function putProjectOcaData() {
    await apiAuthInstance({
      url: `project/update/${applicationId}/`,
      method: 'put',
    })
      .then((response) => {
        setModal((prevState) => ({
          ...prevState,
          loaded: true,
          show: false,
          warningModalShow: false,
        }))
        setApiStatus({
          status: response.data.status,
          message: response.data.message,
          apiStatusModalShow: true,
        })
        getApplications()
      })
      .catch((error) => {
        setApiStatus({
          status: error.response.data.status,
          message: error.response.data.message,
          apiStatusModalShow: true,
        })
      })
  }
  async function putClientApplicationConfirmation() {
    await apiAuthInstance({
      url: `applications/application/confirmation/${applicationId}/`,
      method: 'put',
      data: {
        account: userData.acc,
        password: userData.pwd,
      },
    })
      .then((response) => {
        setModal((prevState) => ({
          ...prevState,
          loaded: true,
          show: false,
          warningModalShow: false,
        }))
        setApiStatus({
          status: response.data.status,
          message: response.data.message,
          apiStatusModalShow: true,
        })
        getApplications()
        setConfirmationSignal(true)
      })
      .catch((error) => {
        setApiStatus({
          status: error.response.data.status,
          message: error.response.data.message,
          apiStatusModalShow: true,
        })
      })
  }
  async function putLinks() {
    await apiAuthInstance({
      url: `account/site-url/`,
      method: 'put',
      data: {
        id: applicationId,
        orca_analytics_url: links.oca_links,
        orca_ma_url: links.ocm_links,
      },
    })
      .then((response) => {
        setModal((prevState) => ({
          ...prevState,
          loaded: true,
          show: false,
          warningModalShow: false,
        }))
        setApiStatus({
          status: response.data.status,
          message: response.data.message,
          apiStatusModalShow: true,
        })
        getApplications()
      })
      .catch((error) => {
        setApiStatus({
          status: error.response.data.status,
          message: error.response.data.message,
          apiStatusModalShow: true,
        })
      })
  }
  async function getSpecificApplication(id) {
    await apiAuthInstance({
      url: 'applications/client-application/',
      method: 'get',
      params: {
        user_permission: 'orca_admin',
        application_id: id,
      },
    })
      .then((response) => {
        setSpecificApplication({
          ...response.data.data,
          applicant_data: {
            ...specificApplication.applicant_data,
            ...response.data.data.applicant_data,
          },
        })
        if (
          response.data.data.application.status === 'formal data pending' ||
          response.data.data.application.status === 'formal data incomplete' ||
          response.data.data.application.status === 'formal data approved'
        ) {
          setIsFormalData(true)
          setModal((prevState) => ({
            ...prevState,
            action: {
              ...initModalAction,
              type: 'formal data review',
            },
          }))
        } else {
          setModal((prevState) => ({
            ...prevState,
            action: {
              ...initModalAction,
              type: 'review',
            },
          }))
        }
      })
      .catch(() => {})
  }
  async function deleteSpecificApplication(id) {
    await apiAuthInstance({
      url: 'applications/client-application/',
      method: 'delete',
      data: {
        application_id: id,
      },
    })
      .then((response) => {
        setModal((prevState) => ({
          ...prevState,
          loaded: true,
          show: false,
          warningModalShow: false,
        }))
        setApiStatus({
          status: response.data.status,
          message: response.data.message,
          apiStatusModalShow: true,
        })
        getApplications()
      })
      .catch((error) => {
        setApiStatus({
          status: error.response.data.status,
          message: error.response.data.message,
          apiStatusModalShow: true,
        })
        getApplications()
      })
  }

  async function sendNotificationEmail(id) {
    await apiAuthInstance({
      url: `applications/notification/`,
      method: 'post',
      data: {
        type: 'bmDone',
        id,
        user_acc: userData.acc,
        user_pwd: userData.pwd,
      },
    })
      .then((response) => {
        setModal((prevState) => ({
          ...prevState,
          loaded: true,
          show: false,
          warningModalShow: false,
          rejectModalShow: false,
        }))
        setApiStatus({
          status: response.data.status,
          message: response.data.message,
          apiStatusModalShow: true,
        })
      })
      .catch((error) => {
        setApiStatus({
          status: error.response.data.status,
          message: error.response.data.message,
          apiStatusModalShow: true,
        })
      })
  }
  const handleModalShow = async (event) => {
    console.log(event.target.name)
    let selectedApplicationData
    switch (event.target.name) {
      case 'links':
        selectedApplicationData = applications.find(
          (application) => application.id === parseInt(event.target.value, 10)
        )
        // await getSpecificApplication(selectedApplicationData.id)
        setApplicationId(selectedApplicationData.id)
        setLinks({
          oca_links: selectedApplicationData.orca_analytics_url,
          ocm_links: selectedApplicationData.orca_ma_url,
        })
        setModal((prevState) => ({
          ...prevState,
          action: {
            ...initModalAction,
            type: 'links',
          },
          show: false,
          linksModalShow: true,
        }))
        break

      case 'members':
        selectedApplicationData = applications.find(
          (application) => application.id === parseInt(event.target.value, 10)
        )
        await getSpecificApplication(selectedApplicationData.id)
        setApplicationId(selectedApplicationData.id)
        setModal((prevState) => ({
          ...prevState,
          action: {
            ...initModalAction,
            type: 'members',
          },
          show: false,
          membersModalShow: true,
        }))
        break
      case 'project':
        selectedApplicationData = applications.find(
          (application) => application.id === parseInt(event.target.value, 10)
        )
        setApplicationId(selectedApplicationData.id)
        setMaCompId(selectedApplicationData.ma_comp_id)
        setSibKey(selectedApplicationData.sib_key)
        setModal((prevState) => ({
          ...prevState,
          action: {
            ...initModalAction,
            type: 'project',
          },
          show: false,
          projectModalShow: true,
        }))
        break
      case 'projectUpdateRecord':
        selectedApplicationData = applications.find(
          (application) => application.id === parseInt(event.target.value, 10)
        )
        setApplicationId(selectedApplicationData.id)
        // getProjectUpdateRecords()
        setModal((prevState) => ({
          ...prevState,
          action: {
            ...initModalAction,
            type: 'projectUpdateRecord',
          },
          show: false,
          projectUpdateRecordModalShow: true,
        }))
        break
      // case 'reload':
      //   // selectedApplicationData = applications.find(
      //   //   (application) => application.id === parseInt(event.target.value, 10)
      //   // )
      //   // setApplicationId(selectedApplicationData.id)
      //   setModal((prevState) => ({
      //     ...prevState,
      //     action: {
      //       ...initModalAction,
      //       type: 'reload',
      //     },
      //     show: false,
      //     warningModalShow: true,
      //   }))
      //   break
      case 'clientApplicationApproved':
        selectedApplicationData = applications.find(
          (application) => application.id === parseInt(event.target.value, 10)
        )
        setApplicationId(selectedApplicationData.id)
        setModal((prevState) => ({
          ...prevState,
          action: {
            ...initModalAction,
            type: 'clientApplicationApproved',
          },
          userDataModalShow: true,
          // warningModalShow: true,
        }))
        break

      case 'clientApplicationDetail':
        selectedApplicationData = applications.find(
          (application) => application.id === parseInt(event.target.value, 10)
        )
        setApplicationId(selectedApplicationData.id)
        setApplicationDetail((prevState) => ({
          ...prevState,
          applicationDetailModalShow: true,
          data: {
            oca_name: selectedApplicationData.oca_name,
            create_time: selectedApplicationData.create_time,
            expired_time: selectedApplicationData.expired_time,
            applicant: selectedApplicationData.applicant,
            version: selectedApplicationData.version,
            ma_comp_id: selectedApplicationData.ma_comp_id,
            sib_key: selectedApplicationData.sib_key,
            orca_analytics_url: selectedApplicationData.orca_analytics_url,
            orca_ma_url: selectedApplicationData.orca_ma_url,
            data_src: selectedApplicationData.data_src,
            application_src: selectedApplicationData.application_src,
            company_name: selectedApplicationData.company_name,
            tax_id: selectedApplicationData.tax_id,
            contact_number: selectedApplicationData.contact_number,
            comp_default_language:
              selectedApplicationData.comp_default_language,
            app_remark: selectedApplicationData.app_remark,
          },
        }))
        setModal((prevState) => ({
          ...prevState,
          action: {
            ...initModalAction,
            type: 'clientApplicationDetail',
          },
        }))
        break
      case 'delete':
        selectedApplicationData = applications.find(
          (application) => application.id === parseInt(event.target.value, 10)
        )
        setApplicationId(selectedApplicationData.id)
        setModal((prevState) => ({
          ...prevState,
          action: {
            ...initModalAction,
            type: 'delete',
          },
          warningModalShow: true,
        }))
        break

      default:
        break
    }
  }

  const handleModalClose = () => {
    setSpecificApplication(initSpecificApplicationData)
    setModal((prevState) => ({
      ...prevState,
      action: initModalAction,
      show: false,
      loaded: true,
      warningModalShow: false,
      linksModalShow: false,
      membersModalShow: false,
      projectModalShow: false,
      projectUpdateRecordModalShow: false,
      userDataModalShow: false,
    }))
    setApiStatus(initApiStatus)
    setIsFormalData(false)
    setProjectUpdateRecords([])
    setApplicationId('')
    setMaCompId('')
    setSibKey('')
    setApplicationDetail(initApplicationDetail)
    setUserData(initUserData)
  }
  const handleModalSubmit = (event) => {
    switch (event.target.name) {
      case 'links':
        setModal((prevState) => ({
          ...prevState,
          linksModalShow: false,
          warningModalShow: true,
        }))
        break
      case 'members':
        setModal((prevState) => ({
          ...prevState,
          membersModalShow: false,
          warningModalShow: true,
        }))
        break
      case 'project':
        setModal((prevState) => ({
          ...prevState,
          membersModalShow: false,
          warningModalShow: true,
        }))
        break
      case 'reload':
        setModal((prevState) => ({
          ...prevState,
          action: {
            ...initModalAction,
            type: 'reload',
          },
          show: false,
          warningModalShow: true,
        }))
        break
      case 'clientApplicationApproved':
        setModal((prevState) => ({
          ...prevState,
          action: {
            ...initModalAction,
            type: 'clientApplicationApproved',
          },
          userDataModalShow: false,
          warningModalShow: true,
        }))
        break
      default:
        break
    }
  }

  const handleWarningModalCancel = (event) => {
    switch (event.target.name) {
      case 'links':
        setModal((prevState) => ({
          ...prevState,
          linksModalShow: true,
          warningModalShow: false,
        }))
        break
      case 'members':
        setModal((prevState) => ({
          ...prevState,
          linkModalShow: true,
          membersModalShow: false,
        }))
        break
      case 'project':
        setModal((prevState) => ({
          ...prevState,
          linksModalShow: true,
          warningModalShow: false,
        }))
        break
      case 'reload':
        setModal((prevState) => ({
          ...prevState,
          action: {
            ...initModalAction,
            type: 'projectUpdateRecord',
          },
          projectUpdateRecordModalShow: true,
          warningModalShow: false,
        }))
        break
      case 'clientApplicationApproved':
        setModal((prevState) => ({
          ...prevState,
          action: {
            ...initModalAction,
            type: 'clientApplicationApproved',
          },
          userDataModalShow: true,
          warningModalShow: false,
        }))
        break
      case 'delete':
        setModal((prevState) => ({
          ...prevState,
          action: {
            ...initModalAction,
            type: 'delete',
          },
          warningModalShow: false,
        }))
        break
      default:
        setModal((prevState) => ({
          ...prevState,
          show: false,
          warningModalShow: false,
        }))
        break
    }
  }

  const handleWarningModalSubmit = (event) => {
    switch (event.target.name) {
      case 'links':
        putLinks()
        break
      case 'members':
        // putApplicationStatus()
        break
      case 'project':
        putProject()
        break
      case 'reload':
        putProjectOcaData()
        break
      case 'clientApplicationApproved':
        putClientApplicationConfirmation()
        break
      case 'delete':
        deleteSpecificApplication(applicationId)
        break
      default:
        break
    }
  }
  useEffect(() => {
    if (!email) return
    getApplications()
  }, [email])

  useEffect(() => {
    if (specificApplication.application.status === 'pending' && !isFormalData) {
      putPendingApplicationStatus(applicationId)
    }
  }, [specificApplication])

  useEffect(() => {
    if (!applicationId) return
    getProjectUpdateRecords()
  }, [applicationId])

  useEffect(() => {
    if (confirmationSignal) {
      sendNotificationEmail(applicationId)
      setConfirmationSignal(initConfirmationSignal)
    }
  }, [confirmationSignal])

  const [search, setSearch] = useState('')
  const sortOptions = [
    {
      name: '建立日期 新到舊',
      value: { key: 'create_time', type: 'date', aesc: false },
    },
    {
      name: '建立日期 舊到新',
      value: { key: 'create_time', type: 'date', aesc: true },
    },
    {
      name: 'OCA Name A~Z',
      value: { key: 'oca_name', type: 'text', aesc: false },
    },
    {
      name: 'OCA Name Z~A',
      value: { key: 'oca_name', type: 'text', aesc: true },
    },
    {
      name: '申請來源 A~Z',
      value: { key: 'application_src', type: 'text', aesc: false },
    },
    {
      name: '申請來源 Z~A',
      value: { key: 'application_src', type: 'text', aesc: true },
    },
  ]
  const [sort, setSort] = useState(sortOptions[0])

  const handleSaveRemarks = async (newRemarks) => {
    try {
      await apiAuthInstance({
        url: `applications/application/${applicationId}/remark`, // Ensure this URL is correct
        method: 'put',
        data: {
          app_remark: newRemarks,
        },
      })

      setApplications((prevState) =>
        prevState.map((app) =>
          app.id === applicationId
            ? {
                ...app,
                app_remark: newRemarks,
              }
            : app
        )
      )
      setApplicationDetail((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          app_remark: newRemarks,
        },
      }))
      setModal((prevState) => ({
        ...prevState,
        action: initModalAction,
        show: false,
        loaded: true,
        applicationDetailModalShow: false,
      }))
    } catch (error) {
      const errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : 'An unexpected error occurred'

      setApiStatus({
        status:
          error.response && error.response.data
            ? error.response.data.status
            : 'error',
        message: errorMessage,
        apiStatusModalShow: true,
      })
    }
  }

  return (
    <Container fluid="true" className="pt-2 pb-3 px-5 mb-5 h-100 w-100">
      <Navbar settings={settings} />
      <Toolbar settings={settings} />
      <ModalSubmitWarning
        settings={{
          submitButton: {
            name: modal.action.type,
            value: modal.action.targetId,
            content: '確認',
            loaded: modal.loaded,
          },
          warningModalShow: modal.warningModalShow,
          handleWarningModalCancel,
          handleModalSubmit: handleWarningModalSubmit,
          handleModalClose,
          warningMessageType: modal.action.type,
        }}
      />
      <ModalApiStatus
        settings={{
          content: {
            status: apiStatus.status,
            message: apiStatus.message,
          },
          apiStatusModalShow: apiStatus.apiStatusModalShow,
          handleModalClose,
        }}
      />
      <ModalApplicationDetail
        settings={{
          data: applicationDetail.data,
          applicationDetailModalShow:
            applicationDetail.applicationDetailModalShow,
          handleModalClose,
          handleSaveRemarks,
        }}
      />
      <Row
        className="d-flex card h-7 mb-2 px-3 h6"
        style={{ flexDirection: 'row' }}
      >
        <Col xs={2} className="text-start my-auto h6 text-orca">
          <span>搜尋條件</span>
        </Col>
        <Col xs={10} className="d-flex my-auto h6 text-orca">
          <span className="ms-auto my-auto">管理帳號/OCA Name&ensp;</span>
          <Form.Control
            className="w-25"
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="搜尋"
          />
          <span className="my-auto ms-3">排序&ensp;</span>
          <Dropdown className="" size="sm" align="end">
            <Dropdown.Toggle className="btn-orcaLight" id="dropdown-basic">
              <FontAwesomeIcon icon={faArrowDownWideShort} />
              &ensp;{sort.name}&ensp;
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {sortOptions.map((option, i) => (
                <Dropdown.Item
                  key={`${i}_${option.name}`}
                  href=""
                  onClick={() => setSort(option)}
                >
                  {option.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      {applications ? (
        <Row className="d-flex card h-78 mb-2 h6 tableList">
          {isOrcaAdmin && (
            <TableList
              settings={{
                tableConfig: [
                  {
                    head: 'BM帳號/管理信箱',
                    dataType: 'text',
                    fieldName: 'applicant',
                    style: { width: '200px' },
                  },
                  // {
                  //   head: '到期時間',
                  //   dataType: 'datetime-tz+8',
                  //   fieldName: 'expired_time',
                  // },
                  // {
                  //   head: '建立時間',
                  //   dataType: 'datetime-tz+8',
                  //   fieldName: 'create_time',
                  // },
                  {
                    head: 'OCA Name',
                    dataType: 'text-center',
                    fieldName: 'oca_name',
                    style: { width: '150px' },
                  },
                  // {
                  //   head: '版本',
                  //   dataType: 'text-center',
                  //   fieldName: 'version',
                  // },
                  {
                    head: '申請來源',
                    dataType: 'text-center',
                    fieldName: 'application_src',
                    style: { width: '80px' },
                  },
                  // {
                  //   head: 'BM 使用者數',
                  //   dataType: 'text-end',
                  //   fieldName: 'text',
                  // },
                  // {
                  //   head: 'MA',
                  //   dataType: 'text-end',
                  //   fieldName: 'ma_comp_id',
                  // },
                  // {
                  //   head: 'sib',
                  //   dataType: 'boolean-pure',
                  //   fieldName: 'sib_key',
                  // },
                  // {
                  //   head: '開戶申請狀態',
                  //   dataType: 'text-center',
                  //   fieldName: 'status',
                  // },
                  {
                    head: 'Orca App工單開站狀態',
                    dataType: 'text-center',
                    fieldName: 'app_status',
                    style: { width: '180px' },
                  },
                  {
                    head: 'OCA網頁狀態',
                    dataType: 'status-indicator',
                    fieldName: 'orca_analytics_status',
                    style: { width: '100px' },
                  },
                  {
                    head: '功能列',
                    dataType: 'management of orca admin',
                    fieldAsValue: 'id',
                    onReviewClick: handleModalShow,
                    style: { width: '300px' },
                  },
                ],
                data: applications
                  .filter(
                    (t) =>
                      t.oca_name.toLowerCase().includes(search.toLowerCase()) ||
                      t.applicant.toLowerCase().includes(search.toLowerCase())
                  )
                  .sort((a, b) => {
                    switch (sort.value.type) {
                      case 'date':
                        return sort.value.aesc
                          ? new Date(a[sort.value.key]) -
                              new Date(b[sort.value.key])
                          : new Date(b[sort.value.key]) -
                              new Date(a[sort.value.key])
                      case 'text':
                        return !sort.value.aesc
                          ? a[sort.value.key].charCodeAt(0) -
                              b[sort.value.key].charCodeAt(0)
                          : b[sort.value.key].charCodeAt(0) -
                              a[sort.value.key].charCodeAt(0)
                      default:
                        return sort.value.aesc
                          ? a[sort.value.key] - b[sort.value.key]
                          : b[sort.value.key] - a[sort.value.key]
                    }
                  }),
                size: 10,
                pagination: true,
              }}
            />
          )}
          {isBmAdmin && (
            <TableList
              settings={{
                tableConfig: [
                  {
                    head: '建立時間',
                    dataType: 'datetime-tz+8',
                    fieldName: 'create_time',
                  },
                  {
                    head: 'BM 使用者數',
                    dataType: 'text-end',
                    fieldName: 'text',
                  },
                  // {
                  //   head: '服務',
                  //   dataType: 'text-center',
                  //   fieldName: '',
                  //   // ex. OCA, OCM
                  // },
                  {
                    head: '開戶申請狀態',
                    dataType: 'text-center',
                    fieldName: 'status',
                  },
                  {
                    head: 'OCA 站點',
                    dataType: 'text-end',
                    fieldName: 'orca_analytics_url',
                  },
                  {
                    head: 'OCM 站點',
                    dataType: 'text-end',
                    fieldName: 'orca_ma_url',
                  },
                  {
                    head: '功能列',
                    dataType: 'management of bm admin',
                    fieldAsValue: 'id',
                    onReviewClick: handleModalShow,
                  },
                ],
                data: applications,
                size: 10,
                pagination: true,
              }}
            />
          )}
          {isBmUser ? (
            <TableList
              settings={{
                tableConfig: [
                  {
                    head: 'BM 帳號 (Email)',
                    dataType: 'text',
                    fieldName: 'applicant',
                  },
                  {
                    head: '建立時間',
                    dataType: 'datetime-tz+8',
                    fieldName: 'create_time',
                  },
                  {
                    head: 'BM 管理員',
                    dataType: 'text-center',
                    fieldName: '',
                  },
                  {
                    head: 'BM 使用者數',
                    dataType: 'text-end',
                    fieldName: 'text',
                  },
                  {
                    head: '服務',
                    dataType: 'text-center',
                    fieldName: '',
                  },
                  {
                    head: '開戶申請狀態',
                    dataType: 'text-center',
                    fieldName: 'status',
                  },
                ],
                data: applications,
                size: 10,
                pagination: true,
              }}
            />
          ) : (
            <div className="d-flex flex-column justify-content-center h-100 w-100">
              <Row
                className="d-flex w-100"
                style={{
                  paddingLeft: '30px',
                }}
              >
                <Spinner
                  className="m-auto p-0"
                  style={{
                    height: '60px',
                    width: '60px',
                  }}
                  animation="border"
                  variant="orcaMid"
                />
              </Row>
              <Row>
                <h4 className="text-center text-orcaMid pt-4">資料載入中</h4>
              </Row>
            </div>
          )}
        </Row>
      ) : (
        <Row className="d-flex card h-78 mb-2 h6 tableList">
          <div className="d-flex flex-column justify-content-center h-100 w-100">
            <Row
              className="d-flex w-100"
              style={{
                paddingLeft: '30px',
              }}
            >
              <Spinner
                className="m-auto p-0"
                style={{
                  height: '60px',
                  width: '60px',
                }}
                animation="border"
                variant="orcaMid"
              />
            </Row>
            <Row>
              <h4 className="text-center text-orcaMid pt-4">資料載入中</h4>
            </Row>
          </div>
        </Row>
      )}

      {/* 專案管理 更新ma_comp_id, sib_key */}
      <Modal show={modal.projectModalShow} size="md">
        <Modal.Header closeButton onHide={handleModalClose}>
          <Modal.Title className="px-4 fs-5">專案管理</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <div className="w-100 text-center text-orca py-3">
            <FontAwesomeIcon icon={faLink} style={{ fontSize: '5rem' }} />
          </div>
          <Form className="w-100">
            <Form.Group className="mb-3 w-100" controlId="">
              <Form.Label className="mb-0 text-orca h6">MA comp_id</Form.Label>
              <div className="d-flex">
                <Form.Control
                  className="w-75"
                  type="text"
                  placeholder="在ma的comp_id"
                  value={maCompId}
                  onChange={(e) => setMaCompId(e.target.value)}
                  disabled={!isOrcaAdmin}
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3 w-100" controlId="">
              <Form.Label className="mb-0 text-orca h6">sib key</Form.Label>
              <div className="d-flex">
                <Form.Control
                  className="w-75"
                  type="text"
                  placeholder="sib key"
                  value={sibKey}
                  onChange={(e) => setSibKey(e.target.value)}
                  disabled={!isOrcaAdmin}
                />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex px-5">
          <div className="d-flex mx-auto">
            <Button variant="secondary" onClick={handleModalClose}>
              取消
            </Button>
            <Button
              variant="orca"
              className="mx-2"
              name="project"
              onClick={handleModalSubmit}
            >
              確定
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* 站點管理 */}
      <Modal show={modal.linksModalShow} size="md">
        <Modal.Header closeButton onHide={handleModalClose}>
          <Modal.Title className="px-4 fs-5">站點管理</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <div className="w-100 text-center text-orca py-3">
            <FontAwesomeIcon icon={faLink} style={{ fontSize: '5rem' }} />
          </div>
          <Form className="w-100">
            <Form.Group className="mb-3 w-100" controlId="">
              <Form.Label className="mb-0 text-orca h6">OCA 站點</Form.Label>
              <div className="d-flex">
                <Form.Control
                  className="w-75"
                  type="text"
                  placeholder="https://taster.orca.punwave.com/"
                  value={links.oca_links}
                  onChange={(e) =>
                    setLinks((prevState) => ({
                      ...prevState,
                      oca_links: e.target.value,
                    }))
                  }
                  disabled={!isOrcaAdmin}
                />
                <Button
                  variant="outline-orca2"
                  className="w-5 ms-1"
                  title="編輯"
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
                <Button
                  variant="outline-orca2"
                  className="w-5 ms-1"
                  title="複製連結"
                  onClick={() => {
                    navigator.clipboard.writeText(links.oca_links)
                  }}
                >
                  <FontAwesomeIcon icon={faCopy} />
                </Button>
              </div>
            </Form.Group>
            <Form.Group className="mb-3 w-100" controlId="">
              <Form.Label className="mb-0 text-orca h6">OCM 站點</Form.Label>
              <div className="d-flex">
                <Form.Control
                  className="w-75"
                  type="text"
                  placeholder="https://orcama-plus.punwave.com"
                  value={links.ocm_links}
                  onChange={(e) =>
                    setLinks((prevState) => ({
                      ...prevState,
                      ocm_links: e.target.value,
                    }))
                  }
                  disabled={!isOrcaAdmin}
                />
                <Button
                  variant="outline-orca2"
                  className="w-5 ms-1"
                  title="編輯"
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
                <Button
                  variant="outline-orca2"
                  className="w-5 ms-1"
                  title="複製連結"
                  onClick={() => {
                    navigator.clipboard.writeText(links.ocm_links)
                  }}
                >
                  <FontAwesomeIcon icon={faCopy} />
                </Button>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex px-5">
          <div className="d-flex mx-auto">
            <Button variant="secondary" onClick={handleModalClose}>
              取消
            </Button>
            <Button
              variant="orca"
              className="mx-2"
              name="links"
              onClick={handleModalSubmit}
            >
              確定
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* 站點管理 */}
      <Modal show={modal.userDataModalShow} size="md">
        <Modal.Header closeButton onHide={handleModalClose}>
          <Modal.Title className="px-4 fs-5">客戶帳戶資料</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <div className="w-100 text-center text-orca py-3">
            <FontAwesomeIcon icon={faLink} style={{ fontSize: '5rem' }} />
          </div>
          <Form className="w-100">
            <Form.Group className="mb-3 w-100" controlId="">
              <Form.Label className="mb-0 text-orca h6">客戶帳號</Form.Label>
              <div className="d-flex">
                <Form.Control
                  className="w-75"
                  type="text"
                  placeholder=""
                  value={userData.acc}
                  onChange={(e) =>
                    setUserData((prevState) => ({
                      ...prevState,
                      acc: e.target.value,
                    }))
                  }
                  // disabled={!isOrcaAdmin}
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3 w-100" controlId="">
              <Form.Label className="mb-0 text-orca h6">客戶密碼</Form.Label>
              <div className="d-flex">
                <Form.Control
                  className="w-75"
                  type="text"
                  placeholder=""
                  value={userData.pwd}
                  onChange={(e) =>
                    setUserData((prevState) => ({
                      ...prevState,
                      pwd: e.target.value,
                    }))
                  }
                  // disabled={!isOrcaAdmin}
                />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex px-5">
          <div className="d-flex mx-auto">
            <Button variant="secondary" onClick={handleModalClose}>
              取消
            </Button>
            <Button
              variant="orca"
              className="mx-2"
              name="clientApplicationApproved"
              onClick={handleModalSubmit}
            >
              確定
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* 成員管理 */}
      <Modal show={modal.membersModalShow} size="lg">
        <Modal.Header closeButton onHide={handleModalClose}>
          <Modal.Title className="px-4 fs-5">成員管理</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: 'rgb(248 248 248)' }}>
          <Row className="py-0 my-0">
            <div style={{ width: '60%', height: '3rem' }} />
            <div className="px-0 text-end" style={{ width: '28%' }}>
              <Form.Group className="mb-3 w-100" controlId="">
                <div className="d-flex">
                  <Form.Control
                    className="py-0 px-2"
                    type="text"
                    placeholder=" search... "
                  />
                  <Button size="sm" variant="orca" title="搜尋">
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </Button>
                </div>
              </Form.Group>
            </div>
            <div className="ps-0 text-end" style={{ width: '12%' }}>
              <Button
                size="sm"
                variant="orca"
                className="w-5"
                title="新建BM使用者"
              >
                新 建&ensp;
                <FontAwesomeIcon icon={faCirclePlus} />
              </Button>
            </div>
          </Row>
          <div
            className="d-flex flex-column p-0 h-100 tableList tableList_members rounded border"
            style={{ backgroundColor: '#fff' }}
          >
            <TableList
              settings={{
                tableConfig: [
                  {
                    head: '帳號(Email)',
                    dataType: 'text',
                    fieldName: 'virtual_acc',
                  },
                  {
                    head: '名稱',
                    dataType: 'text',
                    fieldName: 'virtual_acc',
                  },
                  {
                    head: '建立時間',
                    dataType: 'datetime-tz+8',
                    fieldName: 'create_time',
                  },
                  {
                    head: '權限',
                    dataType: 'text-center',
                    fieldName: 'virtual_acc',
                  },
                  {
                    head: '功能列',
                    dataType: 'view and delete',
                    fieldAsValue: 'id',
                    onReviewClick: handleModalShow,
                  },
                ],
                data: applications,
                size: 10,
                pagination: true,
              }}
            />
          </div>
        </Modal.Body>
      </Modal>

      {/* 查看更新紀錄 */}
      <Modal show={modal.projectUpdateRecordModalShow} size="lg">
        <Modal.Header closeButton onHide={handleModalClose}>
          <Modal.Title className="px-4 fs-5">專案更新紀錄</Modal.Title>
        </Modal.Header>
        {/* 給一個按鈕更新 */}
        <Modal.Body style={{ backgroundColor: 'rgb(248 248 248)' }}>
          <Row className="py-0 my-0">
            <div style={{ width: '60%', height: '3rem' }} />
            <div className="px-0 text-end" style={{ width: '28%' }} />
            <div className="ps-0 text-end" style={{ width: '12%' }}>
              <Button
                size="sm"
                variant="orca"
                className="w-5"
                title="新建BM使用者"
                name="reload"
                onClick={handleModalSubmit}
              >
                更新&ensp;
                <FontAwesomeIcon icon={faRotateRight} />
              </Button>
            </div>
          </Row>
          <div
            className="d-flex flex-column p-0 h-100  tableList_members rounded border"
            style={{ backgroundColor: '#fff' }}
          >
            <TableList
              settings={{
                tableConfig: [
                  {
                    head: '開始更新時間',
                    dataType: 'datetime-tz+8',
                    fieldName: 'create_time',
                  },
                  {
                    head: '狀態更新時間',
                    dataType: 'datetime-tz+8',
                    fieldName: 'edit_time',
                  },
                  {
                    head: '更新狀態',
                    dataType: 'text',
                    fieldName: 'status',
                  },
                ],
                data: projectUpdateRecords,
                size: 10,
                pagination: true,
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
      <Footer />
    </Container>
  )
}
