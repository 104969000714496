import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
// import ModalSubmitWarning from './ModalSubmitWarning'

export default function ModalApplicationDetail(props) {
  const { settings } = props
  const { data } = settings
  const { applicationDetailModalShow, handleModalClose, handleSaveRemarks } =
    settings
  // 使用 useState 來管理備註內容
  const [remarks, setRemarks] = useState(data.app_remark || '')
  // const [originalRemarks, setOriginalRemarks] = useState(data.app_remark || '')

  // const [showSaveAlert, setShowSaveAlert] = useState(false)
  // const [showCloseAlert, setShowCloseAlert] = useState(false)

  const handleRemarksChange = (event) => {
    setRemarks(event.target.value)
  }

  useEffect(() => {
    setRemarks(data.app_remark || '')
    // setOriginalRemarks(data.app_remark || '')
  }, [data])

  // const handleSaveAndClose = () => {
  //   if (remarks !== originalRemarks) {
  //     setShowSaveAlert(true)
  //   } else {
  //     handleSaveRemarks(remarks)
  //     handleModalClose()
  //   }
  // }

  // const handleCancelOrClose = () => {
  //   if (remarks !== originalRemarks) {
  //     setShowCloseAlert(true)
  //   } else {
  //     handleModalClose()
  //   }
  // }

  // const handleWarningModalSubmit = () => {
  //   handleSaveRemarks(remarks)
  //   handleModalClose()
  //   setShowSaveAlert(false)
  // }

  // const handleWarningModalCancel = () => {
  //   setShowCloseAlert(false)
  //   setShowSaveAlert(false)
  // }

  return (
    <>
      {/* 特定工單 */}
      <Modal show={applicationDetailModalShow} size="xl">
        <Modal.Header closeButton onHide={handleModalClose}>
          <Modal.Title className="px-4">{data.oca_name} 的詳細資料</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex px-5">
          <Row className="d-flex h-100 w-100 m-0">
            {/* Applicant Information */}
            <Col
              xs={5}
              className="pt-2 d-flex flex-column px-1 text-orcaDarker lh-md"
            >
              {/* <h5 className="text-orcaMid fw-bolder">詳細資料</h5> */}
              <div className="lh-sm pb-2">
                <Row className="pt-2">
                  <Col xs={4}>工單建立時間:</Col>
                  <Col xs={8} className="text-end">
                    {data.create_time
                      .toString()
                      .split('.')[0]
                      .replace('T', ' ')}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>到期時間:</Col>
                  <Col xs={8} className="text-end">
                    {data.expired_time
                      .toString()
                      .split('.')[0]
                      .replace('T', ' ')}
                  </Col>
                </Row>
              </div>

              <div className="lh-sm pb-2">
                <Row className="pt-2">
                  <Col xs={4}>公司名稱:</Col>
                  <Col xs={8} className="text-end">
                    {data.company_name}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>統一編號:</Col>
                  <Col xs={8} className="text-end">
                    {data.tax_id}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>聯絡電話:</Col>
                  <Col xs={8} className="text-end">
                    {data.contact_number ? data.contact_number : '---'}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>聯絡信箱:</Col>
                  <Col xs={8} className="text-end">
                    {data.applicant ? data.applicant : '---'}
                  </Col>
                </Row>
              </div>
              <div className="lh-sm py-2">
                <Row>
                  <Col xs={4}>資料來源:</Col>
                  <Col xs={8} className="text-end">
                    {data.data_src}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>申請來源:</Col>
                  <Col xs={8} className="text-end">
                    {data.application_src}
                  </Col>
                </Row>
              </div>
            </Col>

            {/* 必要資料  */}
            <Col
              xs={7}
              className="pt-2 d-flex flex-column px-3 text-orcaDarker lh-lg"
            >
              {/* <h5 className="text-orcaMid fw-bolder">---</h5> */}
              <div className="lh-lg ps-2">
                <div className="lh-sm py-2">
                  <Row>
                    <Col xs={4}>ORCA版本:</Col>
                    <Col xs={8} className="text-end">
                      {data.version}
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={4}>OCA 名稱:</Col>
                    <Col xs={8} className="text-end">
                      {data.oca_name}
                    </Col>
                  </Row>
                </div>
                <div className="lh-sm py-2">
                  <Row>
                    <Col xs={5}>MA Compartment ID:</Col>
                    <Col xs={7} className="text-end">
                      {data.ma_comp_id}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={3}>SIB Key:</Col>
                    <Col xs={9} className="text-end">
                      {data.sib_key ? 'OK' : 'NONO'}
                    </Col>
                  </Row>
                </div>
                <div className="lh-sm py-2">
                  <Row>
                    <Col xs={3}>OCA URL</Col>
                    <Col xs={9} className="text-end">
                      {data.orca_analytics_url}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={3}>OCM URL</Col>
                    <Col xs={9} className="text-end">
                      {data.orca_ma_url}
                    </Col>
                  </Row>
                </div>
                <div className="lh-sm py-2">
                  <Row>
                    <Col xs={3}>Language</Col>
                    <Col xs={9} className="text-end">
                      {data.comp_default_language}
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col
              xs={7}
              className="pt-2 d-flex flex-column px-3 text-orcaDarker lh-lg"
            >
              {/* ... 其他内容 ... */}
              <div className="lh-sm py-2">
                <Row>
                  <Col xs={3}>備註</Col>
                  <Col xs={9} className="text-end">
                    <textarea
                      className="form-control"
                      value={remarks}
                      onChange={handleRemarksChange}
                      rows="4"
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-center">
          <Button
            variant="secondary"
            name="apiStatus"
            onClick={handleModalClose}
            // onClick={handleCancelOrClose}
          >
            取消/關閉
          </Button>
          <Button
            variant="orca"
            name="saveRemarks"
            onClick={() => {
              handleSaveRemarks(remarks) // 儲存備註
              handleModalClose() // 關閉 Modal
            }}
            // onClick={handleSaveAndClose}
          >
            儲存備註並關閉
          </Button>
        </Modal.Footer>
      </Modal>

      {/* 儲存備註的確認提示 */}
      {/* <ModalSubmitWarning
        settings={{
          warningModalShow: showSaveAlert,
          warningMessageType: 'saveRemarksAndClose',
          handleModalSubmit: handleWarningModalSubmit,
          handleWarningModalCancel,
        }}
      /> */}

      {/* 關閉的提示 */}
      {/* <ModalSubmitWarning
        settings={{
          warningModalShow: showCloseAlert,
          warningMessageType: 'closeWithoutSaving',
          handleModalSubmit: handleModalClose,
          handleWarningModalCancel,
        }}
      /> */}
    </>
  )
}
